@import "app.scss";
/***************************** EVENTS ******************************/
.event{
  background-position: top;
  background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)), url("/img/marche2.jpg");
  background-size: cover;
  text-align: left;
  min-height: 100vh;
  padding-bottom: 100px;
}

.a-events, .a-events:visited, .a-events:hover {
  display: block;
  text-decoration: inherit;
  color: rgba(0, 0, 0, 0.73);
}

.blog-posts {
  margin: auto;
  max-width: 1000px;
}

.blog-posts .featured {
  width: 100% !important;
  height: 250px !important;
  margin: 0.5em 0 1em 0 !important;
}

.blog-posts .featured .image {
  height: 250px !important;
}
.blog-posts .featured .content {
  height: 250px !important;
}
.blog-posts .row {
  display: flex;
}
.blog-posts .row .post:last-child {
  margin-right: 0 !important;
}
.blog-posts .post {
  flex: 1;
  overflow: hidden;
  background: white;
  height: 200px;
  -moz-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.2);
  margin-right: 1em;
  margin-bottom: 1em;
}
.blog-posts .post:hover {
  -moz-box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.1);
}
.blog-posts .post:hover .image {
  opacity: 0.8;
}
.blog-posts .post .image, .blog-posts .post .content {
  display: inline-block;
  position: relative;
  -moz-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}
.blog-posts .post .image {
  float: left;
  width: 50%;
  height: 200px;
  background-size: cover;
  background-position: center center;
}
.blog-posts .post .image .time {
  background: rgba(255, 255, 255, 0.5);
  width: 50px;
  text-align: center;
  padding: 0.5em 0;
  color: #444;
}
.blog-posts .post .image .time .date {
  font-weight: bolder;
}
.container .blog-posts .post .image .time .month {
  font-size: 0.7rem;
}
.blog-posts .post .content {
  padding: 0.5em 1em;
  width: 50%;
  -moz-box-shadow: -2px 0 2px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -2px 0 2px -1px rgba(0, 0, 0, 0.1);
  box-shadow: -2px 0 2px -1px rgba(0, 0, 0, 0.1);
  height: 200px;
  overflow: scroll;
}
.blog-posts .post .content:before {
  content: '';
  position: absolute;
  background: white;
  width: 10px;
  height: 10px;
  top: 20%;
  left: -5px;
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -moz-box-shadow: -1px 0 2px -1px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: -1px 0 2px -1px rgba(0, 0, 0, 0.1);
  box-shadow: -1px 0 2px -1px rgba(0, 0, 0, 0.1);
}
.blog-posts .post .content h1 {
  font-weight: 600;
  line-height: 2;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.blog-posts .post .content p {
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 0.5em;
  font-family: $font-family2;
}
.blog-posts .post .content .meta .icon-comment {
  line-height: 2;
  margin-top: auto;
}

[class^="icon-"]:before {
  margin-right: 0.5em;
  color: #3498db;
}

/**************************** RESPONSIVE *********************************************/

@media (max-width: 575.98px) {
  .blog-posts .post .image {
    display: none !important;
  }
  .blog-posts .post .content{
    width: 100%;
  }
}

@media (min-width: 576px) and (max-width: 767.98px) {

}

@media (min-width: 768px) and (max-width: 991.98px) {

}

@media (min-width: 992px) and (max-width: 1199.98px) {

}

@media (min-width: 1200px) {

}
